root {
  background-color: #076652;
}
.App {
  text-align: center;
  background-color: #076652;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

p {
  font-size: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.display-linebreak {
  white-space: pre-line;
}

/* Mobile first approach */
.mobile-menu {
  display: block;
}

.desktop-menu {
  display: none;
}

/* Then apply changes for larger screens */
@media screen and (min-width: 800px) {
  .mobile-menu {
    display: none;
  }

  .desktop-menu {
    display: block;
  }
}
